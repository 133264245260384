/* eslint-disable no-unused-expressions */
import { useState, useEffect, useRef, useCallback } from 'react';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import Jsona from 'jsona';

import {
  MIN_CELL_WIDTH_PX,
  CHECKBOX_WIDTH,
} from '@components/aria/UI/Table/constants';
import { TYPES } from '@components/aria/UI/Table/types';
import api from '@services/axios';

import { parseId } from '../services/string';

import useCurrentUser from './useCurrentUser';

const defaultCellWidth = `minmax(${MIN_CELL_WIDTH_PX}px, 1fr)`;
const checkboxWidth = `${CHECKBOX_WIDTH}px`;

const defaultOptions = {
  // userSettings: configuration for saving userSettings on column actions
  userSettings: undefined,
  // selectCell: whether the select cell is rendered as the first column
  selectCell: false,
  // defaultWidth: width which will be applied to each column if it is not defined
  defaultWidth: defaultCellWidth,
};

const createMethodMapping = (initialColumns) => {
  return initialColumns.reduce((acc, column) => {
    if (column.method) {
      acc[column.key] = column.method;
    }
    return acc;
  }, {});
};

const reattachMethods = (columns, methodMapping) => {
  return columns.map((column) => ({
    ...column,
    method: methodMapping[column.key] || column.method,
  }));
};

export default function useColumns(initialColumns = [], options = {}) {
  const dataFormatter = new Jsona();
  const [columns, setColumns] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const [currentSetting, setCurrentSetting] = useState();
  const { currentUser, updateUserSettings } = useCurrentUser();
  const tableRef = useRef();

  const visibleColumns = columns.filter((column) => column.show !== false);
  const columnOptions = { ...defaultOptions, ...options };

  const { defaultWidth, sessionSettings, settings, selectCell } = columnOptions;

  const methodMapping = createMethodMapping(initialColumns);

  useEffect(() => {
    const storedColumnState = sessionStorage.getItem(sessionSettings?.name);
    if (storedColumnState) {
      const columnsFromStorage = JSON.parse(storedColumnState);
      setColumns(reattachMethods(columnsFromStorage, methodMapping));
      setIsInit(false);
    } else {
      initializeColumns();
    }
  }, []);

  useEffect(() => {
    const storedColumnState = sessionStorage.getItem(sessionSettings?.name);
    if (isInit) {
      if (storedColumnState) {
        const columnsFromStorage = JSON.parse(storedColumnState);
        setColumns(reattachMethods(columnsFromStorage, methodMapping)); // Reattach methods
      } else {
        initializeColumns();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isInit) {
      sessionStorage.setItem(sessionSettings?.name, JSON.stringify(columns));
    }
  }, [columns, isInit]);

  useEffect(() => {
    const storedColumnState = sessionStorage.getItem(sessionSettings?.name);
    if (isInit) {
      if (storedColumnState) {
        const columnsFromStorage = JSON.parse(storedColumnState);
        setColumns(reattachMethods(columnsFromStorage, methodMapping)); // Reattach methods
      } else {
        initializeColumns();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (isInit && !isEmpty(columns)) {
      setIsInit(false);
    } else if (!isInit && settings) {
      saveUserSettings(visibleColumns);
    }
  }, [columns]);

  const applyShow = (newCols, prevCols) => {
    return newCols.map((col) => ({
      ...col,
      show: prevCols.find((column) => column.key === col.key)?.show ?? true,
    }));
  };

  const initializeColumns = () => {
    if (!settings || !currentSetting) {
      setColumns((prevColumns) => applyShow(initialColumns, prevColumns));
    } else {
      applyOrderFromSettings();
    }
  };

  const initializeSetting = () => {
    const settingExist = currentUser.settings.find((setting) => {
      return settings.id
        ? setting.component === settings.name &&
            parseId(setting.configurable?.id) === settings.id
        : setting.component === settings.name;
    });

    setCurrentSetting(settingExist ?? null);
  };

  const sortColumns = useCallback(
    (a, b) => {
      const orderFromSettings = currentSetting.fields.map((field) => field.key);
      const aIndex = orderFromSettings.findIndex((col) => col === a.key);
      const bIndex = orderFromSettings.findIndex((col) => col === b.key);
      return aIndex < bIndex ? -1 : 1;
    },
    [currentSetting],
  );

  const applyOrderFromSettings = () => {
    const orderFromSettings = currentSetting.fields.map((field) => field.key);
    const fieldsFromSettings = currentSetting.fields.map((field) => ({
      name: field.key,
      width: field.width,
    }));
    const _visibleColumns = initialColumns.filter((col) =>
      orderFromSettings.includes(col.key),
    );
    const visibleColumnsWithWidth = _visibleColumns.map((col) => ({
      ...col,
      show: true,
      width:
        fieldsFromSettings.find((field) => field.name === col.key).width ??
        defaultWidth,
    }));
    const notVisibleColumns = initialColumns
      .filter((col) => !orderFromSettings.includes(col.key))
      .map((col) => ({
        ...col,
        show: false,
      }));

    const visibleColumnsSorted = visibleColumnsWithWidth.sort(sortColumns);

    const finalOrder = [...visibleColumnsSorted, ...notVisibleColumns];
    setColumns(finalOrder);
  };

  const updateWidths = (newColumnWidths) => {
    setColumns((prevColumns) => {
      const _visibleColumns = prevColumns.filter(
        (column) => column.show !== false,
      );
      const newVisibleColumns = _visibleColumns.map((column, index) => {
        return {
          ...column,
          width: newColumnWidths[index],
        };
      });
      const notVisibleColumns = prevColumns.filter(
        (column) => column.show === false,
      );

      return [...newVisibleColumns, ...notVisibleColumns];
    });
  };

  const handleToggle = (item) => {
    setColumns((prevColumns) => {
      const auxColumns = cloneDeep(prevColumns);
      const currentIndex = auxColumns.findIndex((x) => x.key === item.key);
      auxColumns[currentIndex].show = !item.show;
      return auxColumns;
    });
  };

  const getTableStyle = () => {
    const auxWidths = visibleColumns.map(
      (column) => column.width ?? defaultWidth,
    );
    if (selectCell) {
      auxWidths.unshift(checkboxWidth);
    }

    const tableStyle = {
      gridTemplateColumns: auxWidths.join(' '),
    };

    return tableStyle;
  };

  const tableStyle = getTableStyle();

  const transformWidthToPercentages = (width) => {
    const tableWidth = tableRef.current?.offsetWidth;
    const widthInPixels = parseInt(width, 10);
    const widthInPercetage = (widthInPixels / tableWidth) * 100;
    const truncatedPercentage = widthInPercetage.toFixed(2);
    return Number.isNaN(truncatedPercentage)
      ? width
      : `${truncatedPercentage}%`;
  };

  const transformWidthForSettings = (width) => {
    return width.includes('%') || width.includes('fr')
      ? width
      : transformWidthToPercentages(width);
  };

  const saveUserSettings = useCallback(
    debounce((_columns) => {
      if (!settings) return;

      const appSettings =
        settings.name === TYPES.APP || settings.name === TYPES.ACTIVITY;

      const colsData = _columns.map((column) => {
        const columnData = {
          key: column.key,
        };
        if (column.width && column.width !== defaultWidth) {
          columnData.width = transformWidthForSettings(column.width);
        }
        return columnData;
      });

      const dataToSend = {
        type: 'setting',
        id: currentSetting?.id ?? null,
        component: settings.name,
        fields: colsData,
      };

      if (appSettings) {
        dataToSend.configurable = {
          type: 'app',
          id: settings.id,
        };
        dataToSend.relationshipNames = ['configurable'];
      }

      const JSONApiData = dataFormatter.serialize({
        stuff: dataToSend,
        includeNames: appSettings ? ['configurable'] : undefined,
      });

      const saveFunction = currentSetting
        ? api.jsonAPI().put(`settings/${currentSetting.id}`, JSONApiData)
        : api.jsonAPI().post('settings', JSONApiData);

      saveFunction
        .then(({ data }) => {
          if (currentSetting) {
            updateUserSettings(data);
          } else {
            setIsInit(true);
            setCurrentSetting(data);
          }
        })
        .catch((err) => console.error(err));
    }, 1000),
    [currentSetting],
  );
  return {
    tableRef,
    columns,
    visibleColumns,
    tableStyle,
    setColumns,
    handleToggle,
    updateWidths,
  };
}
