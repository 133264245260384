export const generateAddDocumentsOptions = ({
  canGenerateInvoice = false,
  canGenerateNOA = false,
  regenerateNOA = false,
  regenerateInvoice = false,
}) => {
  const options = [
    {
      key: 'document',
      label: 'Upload document',
    },
  ];

  if (canGenerateNOA) {
    options.unshift({
      key: 'noa',
      label: regenerateNOA ? 'Regenerate NOA' : 'Generate NOA',
    });
  }

  if (canGenerateInvoice) {
    options.unshift({
      key: 'invoice',
      label: regenerateInvoice ? 'Regenerate Invoice' : 'Generate Invoice',
    });
  }

  return options;
};
