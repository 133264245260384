import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import SectionInput from '@components/Config/shared/components/SectionInput';

import s from './ApplicationConfig.module.scss';

const ApplicationConfig = ({ settings, onSettingsChange }) => {
  const sortedArray = Object.entries(settings).sort(
    (a, b) => b[1].values.length - a[1].values.length,
  );

  return (
    <div className={s.wrapper}>
      {sortedArray.map(([sectionName, section]) => {
        return (
          <div className={s.configSection} key={section.caption}>
            <div className={s.title}>{section.caption}</div>
            <div>
              {section.values.map((field) => {
                return (
                  <div className={s.field} key={field.name}>
                    <div className={s.title}>
                      {field.caption}
                      {field.tooltip && (
                        <div className={s.tooltip}>
                          <Tooltip title={field.tooltip}>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <div className={s.input}>
                      <SectionInput
                        field={field}
                        onChange={({ name, value: inputValue }) =>
                          onSettingsChange({
                            config: {
                              [sectionName]: {
                                [name]: inputValue,
                              },
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ApplicationConfig;
