import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';

import useApiGet from '@hooks/useApiGet';
import useBreadcrumb from '@hooks/useBreadcrumb';

import AlertsForm from './AlertsForm';

export default function Alert() {
  const { id: alertId } = useParams();
  const [alert, getAlert, loading] = useApiGet(`api/v1/alerts/${alertId}`);
  const { setBreadcrumb } = useBreadcrumb();
  useEffect(() => {}, []);
  useEffect(() => {
    if (alertId) {
      getAlert();
    }
  }, []);

  useEffect(() => {
    setBreadcrumb('Alerts');
  }, [alert]);

  const panes = [
    {
      menuItem: 'Alert',
      render: () => (
        <Tab.Pane attached={false}>
          <AlertsForm alert={alert} loading={loading} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="d-flex-start" style={{ flexDirection: 'column' }}>
      <div
        style={{
          width: '100vw',
          background:
            'linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(34,125,152,1) 36%, rgba(2,0,36,1) 100%)',
        }}
        className="user-banner"
      />
      <Tab
        style={{ width: '100%' }}
        className="user_tabs"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </div>
  );
}
