import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useFilters from '@hooks/useFilters';
import api from '@services/axios';
import { FILTER_TYPES } from '@components/aria/UI/Table/Filters/filterTypes';
import Table from '@components/aria/UI/Table/Table';
import { getDocumentsTypes } from '@actions';
import downloadFile from '@hooks/downloadFile';

export default function DocumentTypes() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filters, updateFilters] = useFilters();
  const uploadRef = useRef(null);
  const [tableMetaData, setTableMetaData] = useState(1);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const getTemplates = () => {
    setLoading(true);
    api
      .jsonAPI()
      .get('editable_document_types', { params: filters })
      .then((res) => {
        setLoading(false);
        setDocumentTypes(res.data);
        setTableMetaData(res.data.meta?.pagy);
      })
      .catch((err) => {
        console.error('Error on getDocumentTypes', { error: err });
        toast.error(t('Error'));
      });
  };

  const onExportDocumentTypes = (types) => {
    downloadFile(JSON.stringify(types), 'documentTypes', 'txt');
  };

  const onImportDocumentTypes = (e) => {
    const importToast = toast.loading('Importing document types');
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      api
        .jsonAPI()
        .post('editable_document_types/import', { data: JSON.parse(text) })
        .then((res) => {
          setDocumentTypes(res.data);
          toast.success('Document types successfully imported');
        })
        .catch(() => {
          toast.error('Error while importing document types');
        })
        .finally(() => {
          uploadRef.current.value = '';
          toast.dismiss(importToast);
        });
    };
    reader.readAsText(e.target.files[0]);
  };

  const printVisible = (bool) => {
    return bool ? 'Yes' : 'No';
  };

  const COLUMNS = [
    { name: t('Default name'), key: 'hyperscienceName' },
    { name: t('Custom name'), key: 'name' },
    {
      name: t('Visible'),
      key: 'visible',
      filter: FILTER_TYPES.SELECT,
      options: [
        { label: 'Yes', value: 'true', visible: true },
        { label: 'No', value: 'false', visible: true },
      ],
      method: printVisible,
    },
  ];

  const userActions = [
    {
      link: '{id}',
      label: 'Edit',
      icon: 'edit',
    },
    {
      label: 'Delete',
      icon: 'trash',
      modal: true,
      modalConfig: {
        header: t('DeleteItem', { item: 'Document type' }),
        text: t('DeleteModalConfirmation'),
        onClick: (id) => deleteTemplate(id),
        yesButton: t('DeleteItemYes', { item: 'document type' }),
      },
    },
  ];

  useEffect(() => {
    getTemplates();
    dispatch(getDocumentsTypes());
  }, []);

  useEffect(() => {
    getTemplates(filters);
  }, [filters]);

  const tableButtons = [
    {
      label: 'Refresh',
      type: 'secondary',
      icon: 'sync',
      handleClick: () => getTemplates(filters),
    },
    {
      label: 'Import',
      type: 'secondary',
      icon: 'download',
      handleClick: () => uploadRef.current.click(),
    },
    {
      label: 'Export',
      type: 'secondary',
      icon: 'upload',
      handleClick: () => onExportDocumentTypes(documentTypes),
    },
    { label: 'Create', type: 'primary', icon: 'add', link: 'new' },
  ];

  const deleteTemplate = (id) => {
    api
      .jsonAPI()
      .delete(`/editable_document_types/${id}`)
      .then(() => {
        toast.success(t('ItemDeleted', { item: 'Template' }));
        getTemplates();
      })
      .catch((err) => {
        console.error('Error deleting the template', { err });
        toast.error(t('ItemNotDeleted', { item: 'template' }));
      });
  };

  return (
    <>
      <input
        ref={uploadRef}
        type="file"
        style={{ display: 'none' }}
        onChange={onImportDocumentTypes}
      />
      <div style={{ padding: '0 15px' }}>
        <Table
          columns={COLUMNS}
          items={documentTypes}
          totalItems={tableMetaData?.count ?? 0}
          buttons={tableButtons}
          actions={userActions}
          loading={loading}
          noItemsKey="No Templates"
          filters={filters}
          pages={tableMetaData?.last ?? 1}
          updateFilters={updateFilters}
          sessionSettings={{ name: 'DOCUMENTTYPES' }}
        />
      </div>
    </>
  );
}
