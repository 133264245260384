import { Radio } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import Jsona from 'jsona';
import { useParams } from 'react-router-dom';

import api from '@services/axios';

import s from './styles.module.scss';

const RuleCard = ({ title, id: itemId, status, onRuleChange, readOnly }) => {
  const [radioValue, setRadioValue] = useState(status);
  const dataFormatter = new Jsona();
  const { id } = useParams();

  const handleChange = (e, data) => {
    setRadioValue(data.value);

    const values = {
      type: 'rules',
      rules: { [title]: data.value },
    };

    const JSONApiValues = dataFormatter.serialize({
      stuff: values,
    });

    api
      .jsonAPI()
      .put(`/work_items/${id}`, JSONApiValues)
      .then((res) => {
        onRuleChange(res?.data?.rules);
      })
      .catch((err) => {
        console.error('err', err);
      });
  };

  useEffect(() => {
    setRadioValue(status);
  }, [status]);

  return (
    <>
      <div className={clsx(s.cardWrapper, 'rulesWrapper')}>
        <div className={s.cardTitle}>{title}</div>
        <div className={s.cardStatuses}>
          {/* <div className={s.statusReject}>Reject</div> */}
          <div className={clsx(s.statusIgnore, 'statusIgnore')}>
            <Radio
              label="Ignore"
              name={`radioGroup-${itemId}`}
              checked={radioValue === 'ignored'}
              onChange={handleChange}
              value="ignored"
              disabled={readOnly}
            />
          </div>
          <div className={clsx(s.statusFix, 'statusFix')}>
            <Radio
              label="Fix"
              name={`radioGroup-${itemId}`}
              checked={radioValue === 'fixed'}
              onChange={handleChange}
              value="fixed"
              disabled={readOnly}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RuleCard;
