import { Tab } from 'semantic-ui-react';
import clsx from 'clsx';

import DocumentEventsForm from '@components/DocumentEventsForm';

import s from './styles.module.scss';

const DocumentEvents = () => {
  return (
    <div className={clsx('documentEventsWrapper', s.eventsDocumentWrapper)}>
      <Tab panes={panes} menu={{ secondary: true }} />
    </div>
  );
};

export default DocumentEvents;

const panes = [
  {
    menuItem: 'Output',
    render: () => (
      <Tab.Pane>
        <DocumentEventsForm />
      </Tab.Pane>
    ),
  },
];
