import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { toast } from 'react-hot-toast';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import DocumentViewer from '@components/aria/Application/Activity/Hyperscience/DocumentViewer/DocumentViewer';
import api from '@services/axios';
import { getFieldsFromDocument } from '@services/documents';
import { WORK_ITEM_STATUSES } from '@constants';

import s from './NewWindowDocument.module.scss';

const NewWindowDocument = () => {
  const navigate = useNavigate();
  const { id, documentId } = useParams();

  const currentUser = useSelector((state) => state.currentUserReducer.user);

  const [isLoadingWI, setIsLoadingWI] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [workItem, setWorkItem] = useState({});

  useEffect(() => {
    setIsLoadingWI(true);
    api
      .jsonAPI()
      .get(`/work_items/${id}`)
      .then((res) => {
        setIsLoadingWI(false);
        setWorkItem(res.data);
      })
      .catch((err) => {
        navigate('/');
        console.error('err2', err);
      });
  }, [id]);

  const onPageCrop = (cropParams, pageId) => {
    setIsLoading(true);
    api
      .jsonAPI()
      .patch(`work_items/${id}/documents/${documentId}/page_crop`, {
        crop: cropParams,
        page_id: pageId,
      })
      .then(({ data }) => {
        toast.success(t('FileCroppedSuccess'));
        setWorkItem(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toast.error(t('FileCroppedFailed'));
      });
  };

  const fieldsFromDocument = getFieldsFromDocument(
    workItem?.documents?.find((doc) => doc.id === Number(documentId)),
  );
  const currentDoc = workItem?.documents?.find(
    (doc) => doc.id === Number(documentId),
  );
  const batchId = workItem?.batch?.data?.id;
  const batchSubmissionId =
    workItem?.batch?.data?.attributes?.hyperscienceData?.id || '';
  const hyperscienceId = workItem?.hyperscienceData?.id;
  const currentWICount =
    workItem?.batch?.data?.attributes?.workItems?.findIndex(
      (currentWorkItem) =>
        parseInt(currentWorkItem, 10) === parseInt(workItem.id, 10),
    ) + 1;
  const editableForCurrentUser = workItem?.locked?.user?.id === currentUser?.id;
  const readOnly =
    workItem?.status?.name === WORK_ITEM_STATUSES.rejected ||
    workItem?.status?.name === WORK_ITEM_STATUSES.approved ||
    workItem?.status?.name === WORK_ITEM_STATUSES.error ||
    workItem?.status?.name === WORK_ITEM_STATUSES.factored ||
    !editableForCurrentUser;

  const onPageStamp = ({ x1, x2, y1, y2 }, pageId, stampId) => {
    setIsLoading(true);
    api
      .jsonAPI()
      .patch(`work_items/${workItem.id}/documents/${documentId}/stamp`, {
        pageId,
        stamp: {
          x1,
          y1,
          x2,
          y2,
        },
        stampId,
      })
      .then(({ data }) => {
        toast.success(t('FileStampedSuccess'));
        setWorkItem(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toast.error(t('FileStampedFailed'));
      });
  };
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        {!isLoadingWI && (
          <div className={s.headerWrapper}>
            <div className={s.docType}>Document: {currentDoc?.layout_name}</div>
            <div className={s.batchId}>
              Batch # {batchId} ({batchSubmissionId})
            </div>
            <div className={s.workItemId}>
              Work Item {workItem.id} ({hyperscienceId})
            </div>
            <div className={s.workItemId}>
              Work Item {currentWICount}/
              {workItem?.batch?.data?.attributes?.workItems?.length}
            </div>
          </div>
        )}
        {!isLoadingWI && (
          <DocumentViewer
            onChangeDocument={() => {}}
            readOnly={readOnly}
            documents={workItem?.documents || []}
            attachments={
              workItem?.documents?.find((doc) => doc.id === Number(documentId))
                ?.pages || []
            }
            documentsCount={workItem.documents?.length || 0}
            fields={fieldsFromDocument}
            selectedDocument={Number(documentId)}
            isLoadingNewData={isLoading}
            onPageCrop={onPageCrop}
            onPageStamp={onPageStamp}
            stampImage={workItem.stamp}
            defaultDocumentFitToWidth={workItem?.flags?.imageFitToWidth}
          />
        )}

        {isLoadingWI && (
          <div className={s.loaderWrapper}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  );
};

export { NewWindowDocument };
