import api from '@services/axios';

const getTenantInfo = () => {
  return api
    .restAPI()
    .get('/settings/info')
    .then(({ data }) => data?.data);
};

export { getTenantInfo };
