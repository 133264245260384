import { useState, useRef } from 'react';
import { Table as STable, Ref } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import useColumns from '@hooks/useColumns';

import TableHead from './TableHead';
import TableBody from './TableBody';
import TableModal from './TableModal';
import Pagination from './Pagination/Pagination';
import TableSkeleton from './TableSkeleton';
import TableButtons from './TableButtons';
import TableSorter from './TableSorter';
import ExpandedRow from './ExpandedRow';
import SelectAllPages from './SelectAllPages';

export default function Table(props) {
  const {
    removePagination = false,
    columns: propsColumns = [],
    actions = [],
    updateFilters = () => {},
    pages = 1,
    loading = false,
    settings,
    sessionSettings,
    sortColumns = true,
    items,
    className,
    disableScrollToView,
  } = props;

  const columnOptions = {
    sessionSettings,
    settings,
    selectCell: !!props.selectedItems,
  };
  const {
    columns,
    setColumns,
    visibleColumns,
    handleToggle,
    tableRef,
    tableStyle,
    updateWidths,
  } = useColumns(propsColumns, columnOptions);
  const tableContainerRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [modalConfig, setModalConfig] = useState(null);

  if (props.firstLoading) return <TableSkeleton />;

  const setModal = (modalConf) => {
    setModalConfig(modalConf);
    setShowModal(true);
  };

  if (isEmpty(columns)) return null;

  const emptyTable = !items || items.length === 0;
  const showBody = !loading && !emptyTable;

  const tableClassname = showBody
    ? 'desktop-table-container'
    : 'desktop-table-container empty';
  const itemsInPage = Array.isArray(items) ? items.length : 0;

  return (
    <>
      <div className={`buttons-container ${className}`}>
        {props?.toolsComponent && props.toolsComponent}
        {props.buttons?.length > 0 && <TableButtons buttons={props.buttons} />}
        {sortColumns && (
          <TableSorter
            items={columns}
            reorderItems={setColumns}
            toggleItem={handleToggle}
            itemKey="key"
          />
        )}
      </div>
      <SelectAllPages
        pages={pages}
        selectedAll={props.selectedAll}
        selectAllPages={props.selectAllPages}
        filters={props.filters}
        totalItems={props.totalItems}
        setSelectAllPages={props.setSelectAllPages}
        itemsInPage={itemsInPage}
      />
      <div className={`${tableClassname} ${className}`} ref={tableContainerRef}>
        <Ref innerRef={tableRef}>
          <STable basic="very" style={tableStyle}>
            <TableHead
              {...props}
              columns={visibleColumns}
              onResize={updateWidths}
              filters={props.filters}
              updateFilters={updateFilters}
              selectedItems={props.selectedItems}
              selectedAll={props.selectedAll}
              onSelectAll={props.onSelectAll}
              containerRef={tableContainerRef}
            />
            <TableBody
              show={showBody}
              items={props.items}
              onRowClick={props?.onRowClick}
              columns={visibleColumns}
              actions={actions}
              containerRef={tableContainerRef}
              loading={loading}
              setModalConfig={setModal}
              onItemSelect={props.onItemSelect}
              selectedItems={props.selectedItems}
              selectedAll={props.selectedAll}
              selectedItemId={props.selectedItemId}
              disableScrollToView={disableScrollToView}
            />
          </STable>
        </Ref>
        {!showBody && (
          <ExpandedRow loading={loading} noItemsKey={props.noItemsKey} />
        )}
      </div>
      {!removePagination && !loading && props.items?.length > 0 && (
        <Pagination
          page={props.filters?.['page[number]']}
          perPage={props.filters?.['page[size]']}
          totalPages={pages}
          totalItems={props.totalItems}
          onChange={updateFilters}
        />
      )}
      <TableModal
        open={showModal}
        toggleOpen={setShowModal}
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />
    </>
  );
}
