import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';

export default function ViewerWarning({ text = 'SomethingWentWrong' }) {
  const { t } = useTranslation();
  return (
    <Message color="grey">
      <Message.Content>{t(text)}</Message.Content>
    </Message>
  );
}
