/* eslint-disable camelcase */
/* eslint-disable new-cap */
import { useState } from 'react';
import Icon, { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Icon as SuiIcon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Tooltip } from 'antd';
import Jsona from 'jsona';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';

import { ReactComponent as RedoIcon } from '@assets/icons/redo.svg';
import { setWorkItem } from '@actions';
import config from '@config';
import api from '@services/axios';
import NewModal from '@components/Modal/NewModal';
import { ReactComponent as Detach } from '@assets/icons/detach.svg';

import Fields from './Fields';
import s from './styles.module.scss';

const selectStyles = {
  color: 'rgb(34, 125, 152)',
  marginLeft: '-11px',
};

export default function WorkItemDocument({
  wiDocument,
  selectedDocument,
  onSelectDocument,
  onRemove,
  onRestore,
  readOnly,
}) {
  const dataFormatter = new Jsona();
  const workItemId = useSelector((state) => state.workItemReducer.workItem?.id);
  const documentTypes = useSelector(
    (state) => state.documentTypesReducer.documentTypes,
  );
  const {
    id,
    pages,
    layout_name: name,
    document_fields: fields,
    document_type,
    removable,
    discarded,
    restorable,
  } = wiDocument;
  const nPages = pages.length > 0 ? pages.length : 0;
  const [fieldsOpen, setFieldsOpen] = useState(false);
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);
  const dispatch = useDispatch();

  const updateDocumentType = (documentType) => {
    const data = {
      type: 'workItem',
      id: workItemId,
      documents: [
        {
          id: wiDocument.id,
          document_type: documentType,
          layout_name: documentType,
        },
      ],
    };
    const jsonAPIData = dataFormatter.serialize({ stuff: data });
    api
      .jsonAPI()
      .patch(`work_items/${workItemId}/documents`, jsonAPIData)
      .then((res) => {
        toast.success('Updated!');
        // we should update the layout_name of the edited document here.
        dispatch(setWorkItem(res.data));
      })
      .catch((err) => console.error(err));
  };

  const handleChange = ({ label }) => {
    updateDocumentType(label);
  };

  const onKeyPress = (evt) => {
    // do not do anything if the key pressed is not enter (enter keyCode is 13)
    if (evt.keyCode !== 13) return;
    onClickHandler();
  };

  const onClickHandler = () => {
    onSelectDocument(id);
  };

  const getDocumentTypeOptions = () => {
    const documentTypesOptions = [...documentTypes];
    if (!documentTypesOptions.some((option) => option.label === name)) {
      documentTypesOptions.push({
        label: name,
        value: documentTypesOptions.length,
      });
    }

    const sortFunction = (a, b) => {
      const aName = a?.label?.split(' - ')[1];
      const bName = b?.label?.split(' - ')[1];

      return aName >= bName;
    };

    return documentTypesOptions
      .filter((option) => option.visible)
      .sort(sortFunction);
  };

  const documentSelected = id === selectedDocument;

  const docClassName = documentSelected ? 'wiDocumentExpanded' : 'wiDocument';

  const documentType = document_type ?? name;

  const documentTypesOptions = getDocumentTypeOptions();

  return (
    <li>
      <NewModal
        title="Do you want to delete document?"
        onCancel={() => setIsRemovePopupOpen(false)}
        visible={isRemovePopupOpen}
        onCrossClick={() => setIsRemovePopupOpen(false)}
        onSubmit={() => {
          onRemove(wiDocument.id);
          setIsRemovePopupOpen(false);
        }}
      >
        This will delete the {name} document
      </NewModal>
      <header
        className={clsx(s[docClassName], discarded && s.wiDocumentRemoved)}
        role="button"
        tabIndex={0}
        onClick={onClickHandler}
        onKeyDown={onKeyPress}
      >
        <div className={s.header}>
          <div className={s.headerTitle}>
            <Select
              disabled={readOnly}
              defaultValue={documentType}
              bordered={false}
              style={selectStyles}
              onChange={(_, option) => handleChange(option)}
              options={documentTypesOptions}
              onClick={(evt) => {
                evt.stopPropagation();
              }}
              suffixIcon={
                <SuiIcon name="caret down" className={s.headerCaret} />
              }
              labelInValue
              popupMatchSelectWidth={200}
            />
            <div> #{id}</div>
          </div>

          <div className={s.tools}>
            <a
              target="_blank"
              href={`${config.api.url.http}/workitems/${workItemId}/${id}`}
              className={s.action}
              rel="noreferrer"
            >
              <Detach />
            </a>
            {removable && !discarded && !readOnly && (
              <Tooltip title="You can delete manually added documents">
                <SuiIcon
                  name="trash"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsRemovePopupOpen(true);
                  }}
                />
              </Tooltip>
            )}
            {removable && discarded && restorable && !readOnly && (
              <div className={s.action}>
                <Tooltip title="You can restore this document">
                  <div className={clsx(s.redoIcon)}>
                    <RedoIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        onRestore(wiDocument.id);
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex-center-sb">
          <a
            style={{
              textDecoration: 'none',
              color: '#909090',
            }}
            href={wiDocument.pdf_output}
            download={`${wiDocument.original_layout_name}.pdf`}
          >
            <SuiIcon name="download" className={s.downloadIcon} />
            {nPages} pages
          </a>
          <div
            className={s.action}
            onClick={(e) => {
              e.stopPropagation();
              setFieldsOpen((prevState) => !prevState);
            }}
          >
            Fields
            <Icon
              component={
                documentSelected ? CaretDownOutlined : CaretRightOutlined
              }
              className={s.fieldsCaret}
            />
          </div>
        </div>
      </header>
      {fieldsOpen && <Fields fields={fields} />}
    </li>
  );
}
