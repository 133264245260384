import i18n from '@locales/i18n';

export const WORK_ITEM_STATUSES = {
  inProcess: 'in_process',
  // classification: 'classification',
  needsAttention: 'needs_attention',
  paused: 'paused',
  rejected: 'rejected',
  finalReview: 'final_review',
  // autoProcess: 'auto_process',
  approved: 'approved',
  completed: 'completed',
  // failed: 'failed',
  // completedToday: 'completed_today',
  // factored: 'factored',
  replied: 'replied',
  error: 'error',
  deleted: 'deleted',
};

export const WORK_ITEM_DISPLAY_STATUSES = {
  [WORK_ITEM_STATUSES.inProcess]: 'In process',
  // [WORK_ITEM_STATUSES.classification]: 'Classification',
  [WORK_ITEM_STATUSES.needsAttention]: 'Needs attention',
  [WORK_ITEM_STATUSES.paused]: 'Paused',
  [WORK_ITEM_STATUSES.rejected]: 'Rejected',
  [WORK_ITEM_STATUSES.finalReview]: 'Final review',
  // [WORK_ITEM_STATUSES.autoProcess]: 'Auto process',
  [WORK_ITEM_STATUSES.approved]: 'Approved',
  [WORK_ITEM_STATUSES.completed]: 'Completed',
  // [WORK_ITEM_STATUSES.failed]: 'Failed',
  // [WORK_ITEM_STATUSES.completedToday]: 'Completed today',
  // [WORK_ITEM_STATUSES.factored]: 'Factored',
  [WORK_ITEM_STATUSES.replied]: 'Replied',
  [WORK_ITEM_STATUSES.error]: 'Error',
  [WORK_ITEM_STATUSES.deleted]: 'Deleted',
};

export const statusColors = new Map([
  [WORK_ITEM_STATUSES.inProcess, '#F8AC58'],
  // [WORK_ITEM_STATUSES.classification, '#F2711C'],
  [WORK_ITEM_STATUSES.needsAttention, '#FFA300'],
  [WORK_ITEM_STATUSES.paused, '#8D9AAE'],
  [WORK_ITEM_STATUSES.rejected, '#DA1F2C'],
  [WORK_ITEM_STATUSES.finalReview, '#2474BB'],
  // [WORK_ITEM_STATUSES.autoProcess, '#32B394'],
  [WORK_ITEM_STATUSES.approved, '#00BF30'],
  // [WORK_ITEM_STATUSES.completedToday, '#8ACA80'],
  [WORK_ITEM_STATUSES.completed, '#72CDF4'],
  // [WORK_ITEM_STATUSES.factored, '#72CDF4'],
  [WORK_ITEM_STATUSES.replied, '#4B87BC'],
  // [WORK_ITEM_STATUSES.factored, '#8ACA80'],
  [WORK_ITEM_STATUSES.replied, '#64B5CD'],
  [WORK_ITEM_STATUSES.error, '#3D3D3D'],
  [WORK_ITEM_STATUSES.deleted, '#3D3D3D'],
  // [WORK_ITEM_STATUSES.failed, '#ED5565'],
]);

export const AUTH_TOKEN = 'authToken';

export const USER_ROLES = {
  admin: 'admin',
  auditor: 'auditor',
  approver: 'approver',
  super_admin: 'super_admin',
};

export const USER_ROLES_OPTIONS = [
  {
    label: USER_ROLES.admin.split('_').join(' ').toUpperCase(),
    value: USER_ROLES.admin,
  },
  {
    label: USER_ROLES.auditor.split('_').join(' ').toUpperCase(),
    value: USER_ROLES.auditor,
  },
  {
    label: USER_ROLES.approver.split('_').join(' ').toUpperCase(),
    value: USER_ROLES.approver,
  },
  {
    label: USER_ROLES.super_admin.split('_').join(' ').toUpperCase(),
    value: USER_ROLES.super_admin,
  },
];

export const approverStatuses = [
  WORK_ITEM_STATUSES.autoProcess,
  WORK_ITEM_STATUSES.finalReview,
];

export const auditorStatuses = [
  WORK_ITEM_STATUSES.needsAttention,
  WORK_ITEM_STATUSES.paused,
];

export const STATUS_CARDS = [
  {
    color: statusColors.get(WORK_ITEM_STATUSES.inProcess),
    title: i18n.t(WORK_ITEM_DISPLAY_STATUSES.in_process),
    icon: 'hourglass half',
    status: WORK_ITEM_STATUSES.inProcess,
    roles: [],
  },
  {
    color: statusColors.get(WORK_ITEM_STATUSES.needsAttention),
    title: i18n.t(WORK_ITEM_DISPLAY_STATUSES.needs_attention),
    icon: 'search',
    status: WORK_ITEM_STATUSES.needsAttention,
    roles: [],
  },
  {
    color: statusColors.get(WORK_ITEM_STATUSES.rejected),
    title: i18n.t(WORK_ITEM_DISPLAY_STATUSES.rejected),
    icon: 'x',
    status: WORK_ITEM_STATUSES.rejected,
    roles: [],
  },
  {
    color: statusColors.get(WORK_ITEM_STATUSES.paused),
    title: i18n.t(WORK_ITEM_DISPLAY_STATUSES.paused),
    icon: 'pause',
    status: WORK_ITEM_STATUSES.paused,
    roles: [],
  },
  {
    color: statusColors.get(WORK_ITEM_STATUSES.replied),
    title: i18n.t(WORK_ITEM_DISPLAY_STATUSES.replied),
    icon: 'check',
    status: WORK_ITEM_STATUSES.replied,
    roles: [],
  },
  {
    color: statusColors.get(WORK_ITEM_STATUSES.finalReview),
    title: i18n.t(WORK_ITEM_DISPLAY_STATUSES.final_review),
    icon: 'eye',
    status: WORK_ITEM_STATUSES.finalReview,
    roles: [],
  },
  {
    color: statusColors.get(WORK_ITEM_STATUSES.approved),
    title: i18n.t(WORK_ITEM_DISPLAY_STATUSES.approved),
    icon: 'cog',
    status: WORK_ITEM_STATUSES.approved,
    roles: [],
  },
  {
    color: statusColors.get(WORK_ITEM_STATUSES.completed),
    title: i18n.t(WORK_ITEM_DISPLAY_STATUSES.completed),
    icon: 'paper plane',
    status: WORK_ITEM_STATUSES.completed,
    roles: [],
  },
  {
    color: statusColors.get(WORK_ITEM_STATUSES.error),
    title: i18n.t(WORK_ITEM_DISPLAY_STATUSES.error),
    icon: 'exclamation circle',
    status: WORK_ITEM_STATUSES.error,
    roles: [],
  },
  {
    color: statusColors.get(WORK_ITEM_STATUSES.deleted),
    title: i18n.t(WORK_ITEM_DISPLAY_STATUSES.deleted),
    icon: 'trash',
    status: WORK_ITEM_STATUSES.deleted,
    roles: [USER_ROLES.super_admin, USER_ROLES.admin],
  },
];

export const EVENTS_TYPES = {
  transflo: 'transflo',
  aria: 'aria',
};

export const ALERTS_LIST = [
  { id: 1, name: 'No flags' },
  { id: 2, name: '3rd Party Carrier on BOL' },
  { id: 3, name: 'Missing POD' },
  { id: 4, name: 'Missing Receipts' },
  { id: 5, name: 'Generic Bill of Landing' },
  { id: 6, name: 'Incorrect Rate' },
  { id: 7, name: 'Bad Quality Copies' },
  { id: 8, name: 'Damage/Shortage' },
  { id: 9, name: 'Lumper' },
  { id: 10, name: 'Client Invoice' },
  { id: 11, name: 'Missing Bill of Landing/Drop' },
  { id: 12, name: 'Missing Rate Sheet' },
  { id: 13, name: 'Carrier Name on Rate Sheet' },
  { id: 14, name: 'Fuel Advance' },
  { id: 15, name: 'Invoice Age > 30 Days' },
  { id: 16, name: 'Must be verified' },
  { id: 17, name: 'TONU' },
];

export const PaymentSelectOptions = [
  { value: 'ACH', name: 'ACH' },
  { value: 'COMDATA', name: 'COMDATA' },
  { value: 'EFS Card', name: 'EFS Card' },
  { value: 'EFS Money Code', name: 'EFS Money Code' },
  { value: 'WIRE', name: 'WIRE' },
];

export const ApprovalOverrideOptions = [
  { value: 'Pending', name: 'Pending' },
  { value: 'Approved', name: 'Approved' },
  { value: 'Issue Client', name: 'Issue Client' },
  { value: 'Duplicate', name: 'Duplicate' },
];

export const EquipmentTypeOptions = [
  { value: 'Refrigerator', name: 'Refrigerator' },
  { value: 'Dry Van', name: 'Dry Van' },
  { value: 'Flatbed', name: 'Flatbed' },
  { value: 'Hotshot', name: 'Hotshot' },
  { value: 'Power Only', name: 'Power Only' },
  { value: 'RGN', name: 'RGN' },
  { value: 'Other', name: 'Other' },
];

export const ReasonCodeOptions = [
  {
    value: 'Aged Load (> 30 days)',
    name: 'Aged Load (> 30 days)',
  },
  {
    value: 'Broker Fuel Advance',
    name: 'Broker Fuel Advance',
  },
  {
    value: 'Duplicate',
    name: 'Duplicate',
  },
  {
    value: 'Missing Pages',
    name: 'Missing Pages',
  },
  {
    value: 'Missing Signature',
    name: 'Missing Signature',
  },
  {
    value: 'Receipt',
    name: 'Receipt',
  },
  {
    value: 'Truck order not used (TONU)',
    name: 'Truck order not used (TONU)',
  },
  {
    value: 'Truckstop Factoring Fuel Advance',
    name: 'Truckstop Factoring Fuel Advance',
  },
  {
    value: 'Verification Needed: Damages',
    name: 'Verification Needed: Damages',
  },
  {
    value: 'Verification Needed: Detention',
    name: 'Verification Needed: Detention',
  },
  {
    value: 'Verification Needed: FEMA/Disaster',
    name: 'Verification Needed: FEMA/Disaster',
  },
  {
    value: 'Verification Needed: Other',
    name: 'Verification Needed: Other',
  },
  {
    value: 'Verification Needed: Overages/Shortages',
    name: 'Verification Needed: Overages/Shortages',
  },
  {
    value: 'Verification Needed: Permits',
    name: 'Verification Needed: Permits',
  },
  {
    value: 'Verification Needed: Rate by Weight',
    name: 'Verification Needed: Rate by Weight',
  },
];
