import Sidebar from '@components/aria/Sidebar/Sidebar';
import WithCurrentUser from '@services/WithCurrentUser';
import WorkItemDetailed from '@components/WorkItemDetailed';

const DocumentPage = () => {
  return (
    <WithCurrentUser>
      <Sidebar noPadding>
        <WorkItemDetailed />
      </Sidebar>
    </WithCurrentUser>
  );
};

export default DocumentPage;
