import React from 'react';
import { Button, Radio } from 'antd';

import { dayjsWithTimezone } from '@services/helpers';

import styles from './MergeList.module.scss';

const formatDate = (date) =>
  dayjsWithTimezone(date).format('MM/DD/YYYY, HH:mm');

const MergeList = ({ items, onSubmit, onCancel }) => {
  const [selectedItem, setSelectedItem] = React.useState(items[0]?.id);

  const onSelectWorkItem = (event) => {
    setSelectedItem(event.target.value);
  };

  return (
    <div>
      <div style={{ overflow: 'auto' }}>
        <table style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th className={styles.sticky}>Principal</th>
              <th>Email date</th>
              <th>Client Name</th>
              <th>Debtor Name</th>
              <th>Received At</th>
              <th>Status</th>
              <th>Load Number</th>
              <th>Email From</th>
              <th>Subject Line</th>
              <th>Payment Type</th>
              <th>Tag</th>
              <th>Batch #</th>
              <th>Work Item #</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id} className={styles.tr}>
                <td className={styles.sticky}>
                  <Radio
                    value={item.id}
                    checked={selectedItem === item.id}
                    onChange={onSelectWorkItem}
                  />
                </td>
                <td>{formatDate(item.emailedAt)}</td>
                <td>{item.outputFields.client?.name}</td>
                <td>{item.outputFields.debtor?.name}</td>
                <td>{formatDate(item.receivedAt)}</td>
                <td>{item?.status?.caption}</td>
                <td>{item?.loadNumber}</td>
                <td>{item?.batch?.data?.attributes?.email}</td>
                <td>{item?.batch?.data?.attributes?.subjectLine}</td>
                <td>{item?.outputFields?.payment?.type}</td>
                <td>
                  {item?.tags.sort((a, b) => a.localeCompare(b)).join(', ')}
                </td>
                <td>{item?.batch?.data?.id}</td>
                <td>{item?.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button key="submit" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          key="link"
          type="button"
          className={styles.submitRemoveButton}
          onClick={() => onSubmit(selectedItem)}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

// Email date
//
// Client Name
//
// Debtor Name
//
// Received At
//
// Status
//
// Load Number
//
// Email From
//
// Subject Line
//
// Payment Type
//
// Tag
//
// Batch #
//
// Work Item #

export { MergeList };
