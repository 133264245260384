import usePermissions from '@hooks/usePermissions';

import Card from './Card';

const CARDS = [
  {
    title: 'Users',
    icon: 'user',
    path: 'users',
  },
  {
    title: 'Email Templates',
    icon: 'tasks',
    path: 'templates',
  },
  {
    title: 'Document Types',
    icon: 'book',
    path: 'document-types',
  },
  {
    title: 'Output fields',
    icon: 'eye',
    path: 'output-fields',
  },
  {
    title: 'Alerts',
    icon: 'warning',
    path: 'alerts',
  },
  {
    title: 'Tags',
    icon: 'tags',
    path: 'tags',
  },
  {
    title: 'Stamps',
    icon: 'print',
    path: 'stamps',
  },
  {
    title: 'Config',
    icon: 'configure',
    path: 'config',
  },
  {
    title: 'Tenant info',
    icon: 'info',
    path: 'info',
  },
  {
    title: 'Default CC Recipients',
    icon: 'envelope',
    path: 'email-addresses',
  },
  {
    title: 'Clients',
    icon: 'users',
    path: 'clients',
  },
  // { title: 'Teams', subtitle: 'Manage teams', icon: 'users', path: 'teams' },
  // {
  //   title: 'Dashboard',
  //   subtitle: 'Manage dashboards',
  //   icon: 'line graph',
  //   path: 'dashboard',
  // },
];

export default function AdminMain() {
  const {
    canViewSettingsDocumentTypes,
    canViewSettingsHiddenOutputFields,
    canViewTagSettings,
    canViewAlertsSettings,
    canViewSettingsConfig,
    canViewStampsSettings,
    canViewSettingsClients,
  } = usePermissions();

  return (
    <section className="admin-section">
      {CARDS.filter((card) => {
        return !(
          (card.title === 'Hidden output fields' &&
            !canViewSettingsHiddenOutputFields) ||
          (card.title === 'Document Types' && !canViewSettingsDocumentTypes) ||
          (card.title === 'Tags' && !canViewTagSettings) ||
          (card.title === 'Alerts' && !canViewAlertsSettings) ||
          (card.title === 'Config' && !canViewSettingsConfig) ||
          (card.title === 'Stamps' && !canViewStampsSettings) ||
          (card.title === 'Clients' && !canViewSettingsClients)
        );
      }).map((card) => (
        <Card
          key={card.title}
          title={card.title}
          icon={card.icon}
          path={card.path}
        />
      ))}
    </section>
  );
}
