import { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import preval from 'preval.macro';
import dayjs from 'dayjs';

import { getTenantInfo } from './shared/api';
import s from './shared/TenantInfo.module.scss';

const buildTimestamp = preval`module.exports = new Date().getTime();`;
const TenantInfo = () => {
  const lastUpdateMoment = dayjs.unix(buildTimestamp / 1000);
  const formattedUpdateDate = lastUpdateMoment.format('YYYY-MM.DD. HH:mm:ss');

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    try {
      const tenantData = await getTenantInfo();
      setData(tenantData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className={s.loader}>
        <Loader active inline="centered" />
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      {data.length > 0 &&
        data.map((item) => (
          <div key={item.caption} className={s.tenantInfoItem}>
            <div>{item.caption}:</div>
            <div>{item.value}</div>
          </div>
        ))}
      <div className={s.tenantInfoItem}>
        <div>Last frontend deployment:</div>
        <div>{formattedUpdateDate}</div>
      </div>
    </div>
  );
};

export { TenantInfo };
