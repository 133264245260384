import {
  statusColors,
  WORK_ITEM_STATUSES,
  WORK_ITEM_DISPLAY_STATUSES,
} from '@constants';

const displayToStatusKey = Object.entries(WORK_ITEM_DISPLAY_STATUSES).reduce(
  (acc, [key, displayName]) => {
    acc[displayName.toLowerCase()] = key;
    return acc;
  },
  {},
);

const getDynamicColors = (data) => {
  if (
    !data ||
    !Array.isArray(data) ||
    data.length === 0 ||
    !Array.isArray(data[0])
  ) {
    console.warn('Invalid data provided. Returning default color.');
    return [];
  }

  const uniqueDisplayStatuses = [...new Set(data[0].slice(1))];
  return uniqueDisplayStatuses.map((displayName) => {
    const trimmedDisplayName = displayName.toLowerCase();
    const statusKey = displayToStatusKey[trimmedDisplayName];
    return statusColors.get(statusKey) || '#000000'; // Default color if statusKey not found
  });
};

export const clientScoreboardOptions = (data) => ({
  isStacked: true,
  legend: { position: 'bottom', textStyle: { fontSize: 9 }, paging: false },
  chartArea: { width: '90%', height: '70%', top: '7%' },
  legendTextStyle: { fontFamily: 'Roboto', fontSize: 16, fontWeight: 400 },
  hAxis: {
    textStyle: {
      slantedText: true,
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  vAxis: {
    format: '0',
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  colors: getDynamicColors(data),
});

export const userProductivityOptions = {
  seriesType: 'bars',
  series: { 5: { type: 'line' } },
  chartArea: { width: '90%', height: '70%', top: '7%' },
  legendTextStyle: { fontFamily: 'Roboto', fontSize: 16, fontWeight: 400 },
  legend: { position: 'bottom' },
  hAxis: {
    title: 'Users',
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  vAxis: {
    gridlines: {
      count: '2',
    },
    format: '0',
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
};

export const systemThroughputOptions = {
  isStacked: true,
  legend: { position: 'bottom', textStyle: { fontSize: 9 }, paging: false },
  chartArea: { width: '90%', height: '70%', top: '7%' },
  legendTextStyle: { fontFamily: 'Roboto', fontSize: 16, fontWeight: 400 },
  hAxis: {
    textStyle: {
      slantedText: true,
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  vAxis: {
    format: '0',
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  colors: [
    statusColors.get(WORK_ITEM_STATUSES.approved),
    statusColors.get(WORK_ITEM_STATUSES.completed),
    statusColors.get(WORK_ITEM_STATUSES.finalReview),
    statusColors.get(WORK_ITEM_STATUSES.inProcess),
    statusColors.get(WORK_ITEM_STATUSES.paused),
    statusColors.get(WORK_ITEM_STATUSES.rejected),
    statusColors.get(WORK_ITEM_STATUSES.replied),
  ],
};

export const valueOfCompletedWIOptions = {
  legend: { position: 'bottom' },
  chartArea: { width: '90%', height: '70%', top: '7%' },
  legendTextStyle: { fontFamily: 'Roboto', fontSize: 16, fontWeight: 400 },
  hAxis: {
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  vAxis: {
    format: '0',
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  colors: [
    '#007BFF',
    '#20C997',
    '#6610F2',
    '#DC3545',
    '#FFC107',
    '#28A745',
    '#E83E8C',
    '#6E1B23',
    '#ED9AA2',
    '#2474BB',
  ],
};
