import { InputNumber, Select as AntdSelect, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

import unsplitedStyles from '@components/DocumentEventsForm/styles.module.scss';

import s from './PaymentBlock.module.scss';

const PaymentBlock = ({
  readOnly,
  currentPaymentType,
  paymentOptions,
  isFieldsEditDisabled,
  amount,
  defaultContent,
  handlePaymentTypeChange,
  splitPayments,
}) => {
  const [paymentType, setPaymentType] = useState(currentPaymentType);
  const [splitValue, setSplitValue] = useState(splitPayments);
  const [error, setError] = useState('');
  const onSplitPayment = () => {
    if (!isFieldsEditDisabled) {
      if (paymentType === 'SPLIT') {
        setPaymentType(paymentOptions[0]);
        handlePaymentTypeChange(paymentOptions[0].name);
      } else {
        setPaymentType('SPLIT');
        handlePaymentTypeChange('SPLIT', splitValue);
      }
    }
  };

  useEffect(() => {
    const amountSum = splitValue.reduce((acc, item) => {
      return acc + +item.amount;
    }, 0);
    if (amountSum - amount !== 0 && paymentType === 'SPLIT') {
      setError(
        `Amount doesn't match. Difference: ${(amount - amountSum).toFixed(
          2,
        )} $`,
      );
    } else {
      if (JSON.stringify(splitPayments) !== JSON.stringify(splitValue)) {
        handlePaymentTypeChange(currentPaymentType, splitValue);
      }
      setError('');
    }
  }, [amount, splitValue]);

  const onAddPayment = () => {
    if (splitValue.length !== 10 && !isFieldsEditDisabled) {
      const newPayment = [
        ...splitValue,
        {
          id: splitValue.length + 1,
          amount: 0,
          type: paymentOptions[0].name,
        },
      ];
      setSplitValue(newPayment);
    }
  };

  useEffect(() => {
    if (currentPaymentType !== paymentType) {
      setPaymentType(currentPaymentType);
    }
  }, [currentPaymentType]);

  const onRemovePayment = (id) => {
    if (!isFieldsEditDisabled) {
      const newPayments = splitValue.filter((item) => item.id !== id);
      setSplitValue(newPayments);
    }
  };

  const onChangePaymentValue = ({ id, value }) => {
    setSplitValue(
      splitValue.map((item) => {
        if (item.id === id) {
          return { ...item, amount: value };
        }
        return item;
      }),
    );
  };
  const onChangePaymentType = ({ id, value }) => {
    setSplitValue(
      splitValue.map((item) => {
        if (item.id === id) {
          return { ...item, type: value };
        }
        return item;
      }),
    );
  };

  return (
    <>
      <div className={unsplitedStyles.outputBoxName}>
        <div>Payment</div>
        <div className={s.paymentSplitError}>
          {paymentType === 'SPLIT' && error}
        </div>
        {!readOnly && (
          <div
            onClick={onSplitPayment}
            className={unsplitedStyles.outputBlockTitleWithButton}
          >
            {paymentType === 'SPLIT' ? 'Unsplit' : 'Split'}
          </div>
        )}
      </div>
      {paymentType === 'SPLIT' && (
        <>
          <div className={s.totalField}>
            <div className={s.amount}>Total $ {amount}</div>
            {!isFieldsEditDisabled && (
              <div className={s.addButton} onClick={onAddPayment}>
                Add
              </div>
            )}
          </div>
          <div className={s.splitFieldsWrapper}>
            {splitValue.map(({ type, id, amount: fieldAmount }) => (
              <div className={s.spitField} key={id}>
                <div className={s.type}>
                  <AntdSelect
                    disabled={isFieldsEditDisabled}
                    style={{ width: '100%', height: '38px' }}
                    defaultValue={type}
                    value={type}
                    options={paymentOptions}
                    onChange={(value) => onChangePaymentType({ value, id })}
                  />
                </div>
                <div className={s.value}>
                  <InputNumber
                    precision={2}
                    decimalSeparator="."
                    controls={false}
                    addonAfter="$"
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder="0.00"
                    value={fieldAmount}
                    onChange={(value) => {
                      onChangePaymentValue({
                        id,
                        value: value?.toFixed(2) || '',
                      });
                    }}
                    name="amount"
                    disabled={isFieldsEditDisabled}
                  />
                </div>

                <div className={s.remove}>
                  {!isFieldsEditDisabled && (
                    <Tooltip title="Remove">
                      <button
                        className={s.removeButton}
                        type="button"
                        onClick={() => onRemovePayment(id)}
                      >
                        <Icon name="minus" />
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {paymentType !== 'SPLIT' && defaultContent}
    </>
  );
};

export { PaymentBlock };
