import dayjs from 'dayjs';

export const getQuery = (params) => {
  const filledFilters = Object.fromEntries(
    Object.entries(params).filter(([, v]) => v != null),
  );
  return Object.keys(filledFilters).length > 0
    ? new URLSearchParams(filledFilters).toString()
    : '';
};

export const dayjsWithTimezone = (date) => {
  return dayjs(date).tz();
};
