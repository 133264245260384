export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SETTINGS = 'SETTINGS';
export const LOAD_USERS = 'LOAD_USERS';
export const SET_BREADCRUMB = 'SET_BREADCRUMB';
export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';
export const LOAD_APPLICATIONS = 'LOAD_APPLICATIONS';
export const LOAD_FILTERS = 'LOAD_FILTERS';
export const DELETE_FILTERS = 'DELETE_FILTERS';
export const HS_SET_EDITABLE = 'HS_SET_EDITABLE';
export const HS_SELECT_ITEM = 'HS_SELECT_ITEM';
export const HS_RESET_DOCUMENT = 'HS_RESET_DOCUMENT';
export const HS_EDIT_FIELD = 'HS_EDIT_FIELD';
export const HS_SET_FILTERS = 'HS_SET_FILTERS';
export const HS_ADD_ROW = 'HS_ADD_ROW';
export const HS_REMOVE_ROW = 'HS_REMOVE_ROW';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_WORK_ITEM = 'GET_WORK_ITEM';
export const SET_WORK_ITEM = 'SET_WORK_ITEM';
export const RESET_WORK_ITEM = 'RESET_WORK_ITEM';
export const UPDATE_OUTPUT_FIELDS = 'UPDATE_OUTPUT_FIELDS';
export const UPDATE_RULES = 'UPDATE_RULES';
export const UPDATE_ALERTS = 'UPDATE_ALERTS';
export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';

export const RESET_BATCH = 'RESET_BATCH';
export const SET_BATCH_WORK_ITEM = 'SET_BATCH_WORK_ITEM';
// alerts
export const SET_ALERTS_LIST = 'SET_ALERTS_LIST';
// document types:
export const SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES';
export const LOAD_GROUPING_WORK_ITEM_DOCUMENTS_LOADING =
  'LOAD_GROUPING_WORK_ITEM_DOCUMENTS_LOADING';
export const LOAD_GROUPING_WORK_ITEM_DOCUMENTS_SUCCESS =
  'LOAD_GROUPING_WORK_ITEM_DOCUMENTS_SUCCESS';
export const LOAD_GROUPING_WORK_ITEM_DOCUMENTS_ERROR =
  'LOAD_GROUPING_WORK_ITEM_DOCUMENTS_ERROR';
export const ON_DOCUMENT_DROP = 'ON_DOCUMENT_DROP';
export const ON_PAGE_DROP = 'ON_PAGE_DROP';
export const SET_GROUPING_DATA = 'SET_GROUPING_DATA';
export const ADD_UNASSIGNED_DOCUMENT_LOADING =
  'ADD_UNASSIGNED_DOCUMENT_LOADING';
export const ADD_UNASSIGNED_DOCUMENT_SUCCESS =
  'ADD_UNASSIGNED_DOCUMENT_SUCCESS';

export const REMOVE_WORK_ITEM_LOADING = 'REMOVE_WORK_ITEM_LOADING';
export const REMOVE_WORK_ITEM_SUCCESS = 'REMOVE_WORK_ITEM_SUCCESS';

export const ADD_NEW_WORK_ITEM_LOADING = 'ADD_NEW_WORK_ITEM_LOADING';
export const ADD_NEW_WORK_ITEM_SUCCESS = 'ADD_NEW_WORK_ITEM_SUCCESS';

export const RESET_GROUPING = 'RESET_GROUPING';

export const CHANGE_DOCUMENT_TYPE = 'CHANGE_DOCUMENT_TYPE';

export const UPDATE_SAVED_TAGS = 'UPDATE_SAVED_TAGS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_EDITED_FIELD = 'UPDATE_EDITED_FIELD';

export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const SET_ADDITIONAL_FEES_OPTIONS = 'SET_ADDITIONAL_FEES_OPTIONS';
export const SET_APPROVAL_OVERRIDE_OPTIONS = 'SET_APPROVAL_OVERRIDE_OPTIONS';

export const GET_GROUPING_LEFT_SIDE_LOADING = 'GET_GROUPING_LEFT_SIDE_LOADING';
export const GET_GROUPING_LEFT_SIDE_SUCCESS = 'GET_GROUPING_LEFT_SIDE_SUCCESS';
export const GET_GROUPING_LEFT_SIDE_ERROR = 'GET_GROUPING_LEFT_SIDE_ERROR';

export const GET_GROUPING_RIGHT_SIDE_LOADING =
  'GET_GROUPING_RIGHT_SIDE_LOADING';
export const GET_GROUPING_RIGHT_SIDE_SUCCESS =
  'GET_GROUPING_RIGHT_SIDE_SUCCESS';
export const GET_GROUPING_RIGHT_SIDE_ERROR = 'GET_GROUPING_RIGHT_SIDE_ERROR';

export const CLONE_WORK_ITEM_SUCCESS = 'CLONE_WORK_ITEM_SUCCESS';
