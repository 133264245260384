import React, { useEffect } from 'react';

import { getSettings, updateSettings } from '@components/Config/shared/api/api';
import ApplicationLoader from '@ui/ApplicationLoader';
import ApplicationConfig from '@components/Config/shared/components/ApplicationConfig/ApplicationConfig';

const Config = () => {
  const [settings, setSettings] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    getSettings()
      .then((data) => setSettings(data))
      .then(() => setIsLoading(false));
  }, []);

  const onSettingsChange = async (data) => {
    const newData = await updateSettings(data);
    setSettings(newData);
  };

  if (isLoading) {
    return <ApplicationLoader />;
  }
  return (
    <ApplicationConfig
      settings={settings}
      onSettingsChange={onSettingsChange}
    />
  );
};

export default Config;
