import api from '@services/axios';

const getSettings = async () => {
  return api
    .restAPI()
    .get('/settings')
    .then((data) => data.data);
};

const updateSettings = async (data) => {
  return api
    .restAPI()
    .patch('/settings', data)
    .then((res) => res.data);
};

export { getSettings, updateSettings };
