import axios from 'axios';
import Jsona from 'jsona';
import toast from 'react-hot-toast';

import store from '@services/store';
import config from '@config';
import { AUTH_TOKEN } from '@constants';
import { cookies } from '@constants/cookies';
import i18n from '@locales/i18n';

export const handleJSONApiResponse = (response) => {
  if (response.status === 204 || !response.data) return response;

  const auxResponse = { ...response };
  const dataFormatter = new Jsona();
  const deserializedData = dataFormatter.deserialize(response.data);

  auxResponse.data = deserializedData;
  if (response.data.meta) {
    auxResponse.data.meta = response.data.meta;
  }
  if (response.data.links) {
    auxResponse.data.links = response.data.links;
  }
  return auxResponse;
};

const defaultErrorMessage = i18n.t('SomethingWentWrong');

export const handleCashAppError = (e) => {
  let errorMessage = defaultErrorMessage;
  try {
    const error = { ...e.response };
    if (error.status >= 400 && error.status < 500) {
      const parsedError = JSON.parse(error.data.errors[0].detail);
      errorMessage = parsedError.message;
    }
  } catch {
    errorMessage = defaultErrorMessage;
  } finally {
    toast.error(errorMessage);
    console.error(e);
  }
};

const handleUnauthorized = (error) => {
  const { response } = error;
  if (response && response.status === 401) {
    cookies.remove(AUTH_TOKEN, { path: '/' });
    store.dispatch({ type: 'RESET_SESSION' });
    console.error('err', error);
  }

  return Promise.reject(error);
};

class ApiClient {
  jsonAPI = () => {
    const accessToken = cookies.get(AUTH_TOKEN);
    const baseUrl = config.api.url.http || '';
    const instance = axios.create({
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      baseURL: `${baseUrl}/api/v1`,
    });

    instance.defaults.withCredentials = true;
    instance.interceptors.response.use(
      handleJSONApiResponse,
      handleUnauthorized,
    );

    return instance;
  };

  restAPI = () => {
    const accessToken = cookies.get(AUTH_TOKEN);
    const baseUrl = config.api.url.http || '';

    const instance = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      baseURL: `${baseUrl}/api/v1`,
    });

    instance.defaults.withCredentials = true;

    return instance;
  };

  formData = () => {
    const accessToken = cookies.get(AUTH_TOKEN);
    const baseUrl = config.api.url.http || '';

    const instance = axios.create({
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
      baseURL: `${baseUrl}/api/v1`,
    });

    instance.defaults.withCredentials = true;
    instance.interceptors.response.use(handleJSONApiResponse);

    return instance;
  };
}

const api = new ApiClient();

export default api;
