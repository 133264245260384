import WorkItemDocument from '@components/WorkItemDocument';

import s from './styles.module.scss';

export default function WorkItemDocumentList({
  selectedDocument,
  onSelectDocument,
  onDocumentRemove,
  documents = [],
  onDocumentRestore,
  readOnly,
}) {
  return (
    <div className="wi-document-list-container">
      <ul className={s.wiDocumentsList}>
        {documents.map((doc) => {
          return (
            <WorkItemDocument
              key={doc.id}
              wiDocument={doc}
              selectedDocument={selectedDocument}
              onSelectDocument={onSelectDocument}
              onRemove={onDocumentRemove}
              onRestore={onDocumentRestore}
              readOnly={readOnly}
            />
          );
        })}
      </ul>
    </div>
  );
}
