import { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';

import api from '@services/axios';
import { saveFile } from '@hooks/downloadFile';
import { getQuery } from '@services/helpers';
import {
  analyticsCardRequest,
  clientScoreBoardDataRequest,
  systemThroughputRequest,
  userProductivityRequest,
  valueOfCompletedWIRequest,
} from '@components/Dashboard/api';
import {
  clientScoreboardOptions,
  systemThroughputOptions,
  userProductivityOptions,
  valueOfCompletedWIOptions,
} from '@components/Dashboard/shared/helpers';
import DashboardItem from '@components/Dashboard/shared/DashboardItem';
import InfoCard from '@components/Dashboard/shared/InfoCard';

import s from './Dashboard.module.scss';

const { RangePicker } = DatePicker;

// seconds
const updateDelay = 60;

const Dashboard = () => {
  const [chartsData, setChartsData] = useState({});
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [filters, setFilters] = useState({
    valueOfCompletedWI: {
      until: null,
      from: null,
      clientId: null,
    },
    clientScoreboard: {
      until: null,
      from: null,
      clientId: null,
    },
    userProductivity: {
      until: null,
      from: null,
      userId: null,
    },
    systemThroughput: {
      until: null,
      from: null,
      clientId: null,
    },
  });
  const [cardInfo, setCardInfo] = useState([]);

  const [loadingState, setLoadingState] = useState({
    clientScoreboard: false,
    valueOfCompletedWI: false,
    userProductivity: false,
    systemThroughput: false,
  });

  const getClientScoreBoardData = () => {
    setLoadingState((prevState) => ({
      ...prevState,
      clientScoreboard: true,
    }));

    clientScoreBoardDataRequest(getQuery(filters.clientScoreboard)).then(
      (res) => {
        setLoadingState((prevState) => ({
          ...prevState,
          clientScoreboard: false,
        }));
        setChartsData((prevState) => ({
          ...prevState,
          clientScoreboard: res.data,
        }));
        return res.data;
      },
    );
  };

  const getValueOfCompletedWI = () => {
    setLoadingState((prevState) => ({
      ...prevState,
      valueOfCompletedWI: true,
    }));
    valueOfCompletedWIRequest(getQuery(filters.valueOfCompletedWI)).then(
      (res) => {
        setLoadingState((prevState) => ({
          ...prevState,
          valueOfCompletedWI: false,
        }));
        setChartsData((prevState) => ({
          ...prevState,
          valueOfCompletedWI: res.data,
        }));
        return res.data;
      },
    );
  };

  const getUserProductivity = () => {
    setLoadingState((prevState) => ({
      ...prevState,
      userProductivity: true,
    }));
    userProductivityRequest(getQuery(filters.userProductivity)).then((res) => {
      setLoadingState((prevState) => ({
        ...prevState,
        userProductivity: false,
      }));
      setChartsData((prevState) => ({
        ...prevState,
        userProductivity: res.data,
      }));
      return res.data;
    });
  };

  const getSystemThroughput = () => {
    setLoadingState((prevState) => ({
      ...prevState,
      systemThroughput: true,
    }));
    systemThroughputRequest(getQuery(filters.systemThroughput)).then((res) => {
      setLoadingState((prevState) => ({
        ...prevState,
        systemThroughput: false,
      }));
      setChartsData((prevState) => ({
        ...prevState,
        systemThroughput: res.data,
      }));
      return res.data;
    });
  };

  // eslint-disable-next-line consistent-return
  const onApplyFilters = async (chartType) => {
    switch (chartType) {
      case 'clientScoreboard': {
        await getClientScoreBoardData();
        break;
      }
      case 'valueOfCompletedWI': {
        await getValueOfCompletedWI();
        break;
      }
      case 'userProductivity': {
        await getUserProductivity();
        break;
      }
      case 'systemThroughput': {
        await getSystemThroughput();
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log("chartType doesn't exist");
      }
    }
  };

  const getChartsData = () => {
    getClientScoreBoardData();
    getValueOfCompletedWI();
    getUserProductivity();
    getSystemThroughput();
  };

  const getCardsInfo = async () => {
    analyticsCardRequest().then(({ data }) => setCardInfo(data));
  };

  const downloadReport = async (type) => {
    switch (type) {
      case 'clientScoreboard': {
        return clientScoreBoardDataRequest(
          getQuery({ xlsx: true, ...filters[type] }),
          {
            responseType: 'blob',
          },
        ).then((blob) => {
          saveFile(blob.data, 'clientScoreboard', 'xlsx');
        });
      }
      case 'valueOfCompletedWI': {
        return valueOfCompletedWIRequest(
          getQuery({ xlsx: true, ...filters[type] }),
          {
            responseType: 'blob',
          },
        ).then((blob) => {
          saveFile(blob.data, 'valueOfCompletedWI', 'xlsx');
        });
      }
      case 'userProductivity': {
        return userProductivityRequest(
          getQuery({ xlsx: true, ...filters[type] }),
          {
            responseType: 'blob',
          },
        ).then((blob) => {
          saveFile(blob.data, 'userProductivity', 'xlsx');
        });
      }
      case 'systemThroughput': {
        return systemThroughputRequest(
          getQuery({ xlsx: true, ...filters[type] }),
          {
            responseType: 'blob',
          },
        ).then((blob) => {
          saveFile(blob.data, 'systemThroughput', 'xlsx');
        });
      }
      default: {
        // eslint-disable-next-line no-console
        console.log(`Unknown report type: ${type}`);
        return null;
      }
    }
  };
  const getClients = () => {
    api
      .jsonAPI()
      .get('/clients')
      .then(({ data }) => {
        setClients(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getUsers = () => {
    api
      .jsonAPI()
      .get('/users/names')
      .then(({ data }) => {
        setUsers(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onClearFilters = (type) => {
    setFilters({
      ...filters,
      [type]: {},
    });
  };

  useEffect(() => {
    getClientScoreBoardData();
  }, [updateTrigger]);

  useEffect(() => {
    getCardsInfo();
    getUsers();
    getChartsData();
    getClients();
    const updateTimer = setInterval(
      () => setUpdateTrigger((prevState) => prevState + 1),
      updateDelay * 1000,
    );
    return () => {
      clearTimeout(updateTimer);
    };
  }, []);

  return (
    <div style={{ padding: 10 }}>
      <h1 className={s.title}>Analytics</h1>
      {cardInfo?.length > 0 && (
        <div className={s.cardWrapper}>
          {cardInfo
            .sort((a, b) => a.order - b.order)
            .map((card) => (
              <InfoCard
                key={card.order}
                value={card.value}
                title={card.title}
                changePercent={card.change_percent}
                subTitle={card.subtitle}
              />
            ))}
        </div>
      )}
      <div className={s.chartsWrapper}>
        <div className={s.chartBlock}>
          <DashboardItem
            isLoading={loadingState.userProductivity}
            onApplyFilters={() => onApplyFilters('userProductivity')}
            activeFiltersCount={
              Object.values(filters.userProductivity).filter((value) => value)
                .length
            }
            onClearFilters={() => onClearFilters('userProductivity')}
            title="User productivity"
            onDownloadReport={() => downloadReport('userProductivity')}
            filtersComponent={
              <div className={s.filters}>
                <RangePicker
                  value={[
                    filters.userProductivity.from
                      ? dayjs(filters.userProductivity.from)
                      : dayjs().subtract(7, 'days'),
                    filters.userProductivity.until
                      ? dayjs(filters.userProductivity.until)
                      : dayjs(),
                  ]}
                  onChange={(_, [from, until]) => {
                    setFilters((prev) => ({
                      ...prev,
                      userProductivity: {
                        ...prev.userProductivity,
                        from,
                        until,
                      },
                    }));
                  }}
                />
                <Select
                  style={{
                    width: '100%',
                  }}
                  allowClear
                  placeholder="Select user"
                  onClear={() =>
                    setFilters((prev) => ({
                      ...prev,
                      userProductivity: {
                        ...prev.userProductivity,
                        userId: null,
                      },
                    }))
                  }
                  value={filters.userProductivity.userId}
                  options={users}
                  showSearch
                  filterOption={(inputValue, option) => {
                    return option?.name
                      ?.toLowerCase()
                      ?.includes(inputValue.toLowerCase());
                  }}
                  fieldNames={{ label: 'name', value: 'id' }}
                  onSelect={(selected) =>
                    setFilters((prev) => ({
                      ...prev,
                      userProductivity: {
                        ...prev.userProductivity,
                        userId: selected,
                      },
                    }))
                  }
                />
              </div>
            }
            chartComponent={
              <Chart
                className={s.chartWrapper}
                chartType="ComboChart"
                data={chartsData.userProductivity}
                options={userProductivityOptions}
              />
            }
            loaderWrapperStyles={{
              height: '515px',
            }}
          />
        </div>
        <div className={s.chartBlock}>
          <DashboardItem
            isLoading={loadingState.systemThroughput}
            onApplyFilters={() => onApplyFilters('systemThroughput')}
            activeFiltersCount={
              Object.values(filters.systemThroughput).filter((value) => value)
                .length
            }
            onClearFilters={() => onClearFilters('systemThroughput')}
            title="System Throughput"
            onDownloadReport={() => downloadReport('systemThroughput')}
            filtersComponent={
              <div className={s.filters}>
                <RangePicker
                  value={[
                    filters.systemThroughput.from
                      ? dayjs(filters.systemThroughput.from)
                      : dayjs().subtract(7, 'days'),
                    filters.systemThroughput.until
                      ? dayjs(filters.systemThroughput.until)
                      : dayjs(),
                  ]}
                  onChange={(_, [from, until]) => {
                    setFilters((prev) => ({
                      ...prev,
                      systemThroughput: {
                        ...prev.systemThroughput,
                        from,
                        until,
                      },
                    }));
                  }}
                />
                <Select
                  style={{
                    width: '100%',
                  }}
                  allowClear
                  placeholder="Select client"
                  onClear={() =>
                    setFilters((prev) => ({
                      ...prev,
                      systemThroughput: {
                        ...prev.systemThroughput,
                        clientId: null,
                      },
                    }))
                  }
                  value={filters.systemThroughput.clientId}
                  options={clients}
                  showSearch
                  filterOption={(inputValue, option) => {
                    return option?.name
                      ?.toLowerCase()
                      ?.includes(inputValue.toLowerCase());
                  }}
                  fieldNames={{ label: 'name', value: 'id' }}
                  onSelect={(selected) =>
                    setFilters((prev) => ({
                      ...prev,
                      systemThroughput: {
                        ...prev.systemThroughput,
                        clientId: selected,
                      },
                    }))
                  }
                />
              </div>
            }
            chartComponent={
              <Chart
                className={s.chartWrapper}
                chartType="ColumnChart"
                data={chartsData.systemThroughput}
                options={systemThroughputOptions}
              />
            }
            loaderWrapperStyles={{
              height: '515px',
            }}
          />
        </div>
        <div className={s.chartBlock}>
          <DashboardItem
            isLoading={loadingState.clientScoreboard}
            onApplyFilters={() => onApplyFilters('clientScoreboard')}
            onClearFilters={() => onClearFilters('clientScoreboard')}
            title="Client scoreboard"
            onDownloadReport={() => downloadReport('clientScoreboard')}
            activeFiltersCount={
              Object.values(filters.clientScoreboard).filter((value) => value)
                .length
            }
            filtersComponent={
              <div className={s.filters}>
                <RangePicker
                  value={[
                    filters.clientScoreboard.from
                      ? dayjs(filters.clientScoreboard.from)
                      : dayjs().subtract(7, 'days'),
                    filters.clientScoreboard.until
                      ? dayjs(filters.clientScoreboard.until)
                      : dayjs(),
                  ]}
                  onChange={(_, [from, until]) => {
                    setFilters((prev) => ({
                      ...prev,
                      clientScoreboard: {
                        ...prev.clientScoreboard,
                        from,
                        until,
                      },
                    }));
                  }}
                />
                <Select
                  style={{
                    width: '100%',
                  }}
                  allowClear
                  placeholder="Select client"
                  onClear={() =>
                    setFilters((prev) => ({
                      ...prev,
                      clientScoreboard: {
                        ...prev.clientScoreboard,
                        clientId: null,
                      },
                    }))
                  }
                  value={filters.clientScoreboard.clientId}
                  onSelect={(selected) =>
                    setFilters((prev) => ({
                      ...prev,
                      clientScoreboard: {
                        ...prev.clientScoreboard,
                        clientId: selected,
                      },
                    }))
                  }
                  options={clients}
                  showSearch
                  filterOption={(inputValue, option) => {
                    return option?.name
                      ?.toLowerCase()
                      ?.includes(inputValue.toLowerCase());
                  }}
                  fieldNames={{ label: 'name', value: 'id' }}
                />
              </div>
            }
            chartComponent={
              <Chart
                className={s.chartWrapper}
                chartType="ColumnChart"
                data={chartsData.clientScoreboard}
                options={clientScoreboardOptions(chartsData.clientScoreboard)}
              />
            }
            loaderWrapperStyles={{
              height: '515px',
            }}
          />
        </div>
        <div className={s.chartBlock}>
          <DashboardItem
            isLoading={loadingState.valueOfCompletedWI}
            onApplyFilters={() => onApplyFilters('valueOfCompletedWI')}
            onClearFilters={() => onClearFilters('valueOfCompletedWI')}
            title="Value of completed work items"
            activeFiltersCount={
              Object.values(filters.valueOfCompletedWI).filter((value) => value)
                .length
            }
            onDownloadReport={() => downloadReport('valueOfCompletedWI')}
            filtersComponent={
              <div className={s.filters}>
                <RangePicker
                  value={[
                    filters.valueOfCompletedWI.from
                      ? dayjs(filters.valueOfCompletedWI.from)
                      : dayjs().subtract(7, 'days'),
                    filters.valueOfCompletedWI.until
                      ? dayjs(filters.valueOfCompletedWI.until)
                      : dayjs(),
                  ]}
                  onChange={(_, [from, until]) => {
                    setFilters((prev) => ({
                      ...prev,
                      valueOfCompletedWI: {
                        ...prev.valueOfCompletedWI,
                        from,
                        until,
                      },
                    }));
                  }}
                />
                <Select
                  style={{
                    width: '100%',
                  }}
                  allowClear
                  placeholder="Select client"
                  onClear={() =>
                    setFilters((prev) => ({
                      ...prev,
                      valueOfCompletedWI: {
                        ...prev.valueOfCompletedWI,
                        clientId: null,
                      },
                    }))
                  }
                  value={filters.valueOfCompletedWI.clientId}
                  onSelect={(selected) =>
                    setFilters((prev) => ({
                      ...prev,
                      valueOfCompletedWI: {
                        ...prev.valueOfCompletedWI,
                        clientId: selected,
                      },
                    }))
                  }
                  options={clients}
                  showSearch
                  filterOption={(inputValue, option) => {
                    return option?.name
                      ?.toLowerCase()
                      ?.includes(inputValue.toLowerCase());
                  }}
                  fieldNames={{ label: 'name', value: 'id' }}
                />
              </div>
            }
            chartComponent={
              <Chart
                className={s.chartWrapper}
                chartType="AreaChart"
                data={chartsData.valueOfCompletedWI}
                options={valueOfCompletedWIOptions}
              />
            }
            loaderWrapperStyles={{
              height: '515px',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
