// import Avatar from './Avatar';

import Avatar from '@ui/Events/Avatar';
import Message from '@ui/Events/Message';
import AddEvent from '@ui/Events/AddEvent';

import s from './styles.module.scss';

const fakeEvent = {
  id: 0,
  body: 'event body',
  status: '1',
  title: 'event title',
  // file: 'event file',
  updated_at: '12:00',
};

const Event = () => {
  return (
    <div className="smp-event-container">
      <Avatar imageUrl={null} />
      <Message event={fakeEvent} timestampKey="updated_at" withStyled />
    </div>
  );
};

const DocumentActivities = () => {
  const date = 'December 20, 2021';
  const addEventHandler = true;
  return (
    <div className={s.activitiesList}>
      <div>
        <div className="smp-event-date-container">{date}</div>
        <div className="daily-events-container">
          {addEventHandler && <AddEvent />}
          <Event />
          {/* {events.map((event) => ( */}

          {/* ))} */}
        </div>
      </div>
    </div>
  );
};

export default DocumentActivities;
