import { useState } from 'react';
import { Modal, Select as AntdSelect } from 'antd';
import { Button, Icon } from 'semantic-ui-react';
import clsx from 'clsx';
import { FileUploader } from 'react-drag-drop-files';
import { useSelector } from 'react-redux';

import s from '@components/WorkItemDocuments/styles.module.scss';
import { ReactComponent as UploadIcon } from '@assets/icons/uploadFileIcon.svg';

const UploadFilesModal = ({ uploadModalOpen, onCancel, onSubmit }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadAreaActive, setUploadAreaActive] = useState(false);
  const [documentType, setDocumentType] = useState(null);
  const documentTypes = useSelector(
    (state) => state.documentTypesReducer.documentTypes,
  );

  const handleCancel = () => {
    cleanUp();
    onCancel();
  };
  const uploadModalSubmit = () => {
    handleSubmit();
  };

  const handleUpload = (file) => {
    setUploadedFile(file);
  };

  const cleanUp = () => {
    setUploadedFile(null);
    setUploadAreaActive(false);
    setDocumentType(null);
  };

  const handleSubmit = () => {
    try {
      onSubmit({
        fileType: documentType,
        file: uploadedFile,
      });
    } finally {
      onCancel();
      cleanUp();
    }
  };
  return (
    <>
      <Modal
        maskClosable={false}
        centered
        width={900}
        destroyOnClose
        open={uploadModalOpen}
        onCancel={handleCancel}
        title="Upload new document"
        footer={[
          <Button key="submit" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            disabled={!uploadedFile?.name || !documentType}
            key="link"
            type="button"
            className={s.submitUploadButton}
            onClick={uploadModalSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <AntdSelect
          labelInValue
          value={documentType}
          placeholder="Select document type"
          options={documentTypes.filter((type) => type.visible)}
          onSelect={(_, option) => setDocumentType(option)}
          style={{ width: '230px', marginBottom: '16px' }}
        />
        <FileUploader
          multiple={false}
          fileOrFiles={uploadedFile || null}
          disabled={uploadedFile?.name}
          hoverTitle=" "
          classes={`${s.dropArea} ${s.dropZone}`}
          onDraggingStateChange={setUploadAreaActive}
          handleChange={handleUpload}
          types={['PDF', 'XPS', 'TIFF', 'TIF', 'PNG', 'JPG', 'JPEG']}
        >
          <section className={s.dragAndDropArea}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <Icon
              className={clsx(
                s.removeIcon,
                !uploadedFile?.name && s.removeIconTransparent,
              )}
              name="close"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setUploadedFile(null);
              }}
            />
            <UploadIcon
              className={clsx(
                uploadAreaActive || uploadedFile ? s.activeUploadIcon : null,
              )}
            />
            <p
              className={clsx(
                s.uploadText,
                uploadAreaActive && s.activeUploadText,
              )}
            >
              {uploadedFile?.name || `Select a file to upload`}
            </p>
          </section>
        </FileUploader>
      </Modal>
    </>
  );
};

export default UploadFilesModal;
