import { useEffect } from 'react';
import { withFormik } from 'formik';
import { array, object, string } from 'yup';
import { Button, Icon } from 'semantic-ui-react';
import {
  Select as AntdSelect,
  Input as AntdInput,
  Upload,
  Checkbox,
  Dropdown,
} from 'antd';
import clsx from 'clsx';
import { CloseOutlined } from '@ant-design/icons';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

import styles from '@components/forms/NewEmailForm/NewEmailForm.module.scss';
import UploadedFile from '@components/EmailClient/components/UploadedFile/UploadedFile';

const { TextArea } = AntdInput;

const NewEmailForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toOptions,
  ccOptions,
  setTouched,
  isValid,
  clientEmail,
  debtorEmail,
  visible,
  readOnly,
}) => {
  const documents = useSelector(
    (state) => state.workItemReducer.workItem.documents,
  )
    .filter(
      (document) =>
        !document.discarded &&
        !values.attachments.find((file) => file.key === document.id),
    )
    .map((document) => ({
      key: document.id,
      label: `${document.layout_name} ${document?.id && `#${document.id}`}`,
      size: document.byte_size,
    }));

  const removeFile = async (id) => {
    const newFiles = values.attachments.filter((file) => file.uid !== id);
    setFieldValue('attachments', newFiles);
    if (newFiles.length <= 1) {
      setFieldValue('toMergeDocuments', false);
    }
  };

  const onToggleToCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      const newToList = values?.to.concat(name);
      setFieldValue('to', newToList);
    } else {
      const newToList = values?.to.filter((email) => email !== name);
      setFieldValue('to', newToList);
    }
  };

  const onSelectFileFromSelect = (e) => {
    const selectedKey = parseInt(e.key, 10);
    const currentDocument = documents.find((file) => file.key === selectedKey);
    if (currentDocument) {
      setFieldValue('attachments', [...values.attachments, currentDocument]);
    }
  };

  useEffect(() => {
    if (errors?.to?.length || errors?.cc?.length) {
      toast.error(
        'One of the addresses was not recognized. Please make sure that all addresses are properly formed',
      );
    }
  }, [errors.to, errors.cc]);
  const onUploadFile = (info) => {
    handleChange({ target: { name: 'attachments', value: info.fileList } });
  };

  if (!visible) return null;

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit}>
      <div className={styles.fieldsWrapper}>
        <div className={clsx(styles.fieldWrapper, styles.firstField)}>
          <div className={styles.fieldTitle}>To</div>
          <div className={styles.fieldInput}>
            <AntdSelect
              mode="tags"
              allowClear
              style={{ width: '100%' }}
              value={values.to}
              status={errors?.to?.length > 0 ? 'error' : 'default'}
              placeholder="Please select"
              onChange={(e) => {
                setFieldValue('to', e);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setTouched({ to: true });
                }, 0);
              }}
              disabled={readOnly}
              options={toOptions?.filter((item) => item.value)}
            />
            <Checkbox
              disabled={!clientEmail || readOnly}
              name={clientEmail}
              checked={values?.to?.includes(clientEmail)}
              onClick={onToggleToCheckbox}
            >
              Client Email
            </Checkbox>
            <Checkbox
              disabled={!debtorEmail || readOnly}
              checked={values?.to?.includes(debtorEmail)}
              name={debtorEmail}
              onClick={onToggleToCheckbox}
            >
              Debtor Email
            </Checkbox>
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldTitle}>CC</div>
          <div className={styles.fieldInput}>
            <AntdSelect
              mode="tags"
              allowClear
              disabled={readOnly}
              name="cc"
              style={{ width: '100%' }}
              placeholder="Please select"
              value={values.cc}
              status={errors?.cc?.length ? 'error' : 'default'}
              onBlur={() => {
                setTimeout(() => {
                  setTouched({ cc: true });
                }, 0);
              }}
              onChange={(e) => {
                setFieldValue('cc', e);
              }}
              options={ccOptions?.filter((item) => item.value)}
            />
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldTitle}>From</div>
          <div className={styles.fieldInput}>
            <AntdInput value={values.from} disabled />
          </div>
        </div>
        <div className={clsx(styles.fieldWrapper, styles.lastField)}>
          <div className={styles.fieldTitle}>Subject</div>
          <div className={styles.fieldInput}>
            <AntdInput
              disabled={readOnly}
              name="subjectLine"
              onChange={handleChange}
              value={values.subjectLine}
            />
          </div>
        </div>
        <TextArea
          border="true"
          value={values.body}
          className={styles.textArea}
          name="body"
          onChange={handleChange}
          placeholder="Body"
          disabled={readOnly}
          onBlur={handleBlur}
          error={errors.description && touched.description}
        />
        {values?.attachments?.length > 0 && (
          <div className={styles.filesWrapper}>
            <div className={styles.filesList}>
              {values.attachments.map((file) => (
                <UploadedFile
                  file={file}
                  icon={
                    <CloseOutlined
                      className={styles.closeIcon}
                      size={3}
                      onClick={() => removeFile(file.uid)}
                    />
                  }
                  key={file.key || file.uid}
                />
              ))}
            </div>
            {errors.attachments && (
              <div className={styles.error}>{errors.attachments}</div>
            )}
          </div>
        )}
      </div>
      <div className={styles.btsWrapper}>
        <div className={styles.left}>
          <Upload
            disabled={readOnly}
            name="file"
            onChange={onUploadFile}
            showUploadList={false}
            fileList={values.attachments}
            beforeUpload={() => false}
          >
            <Button type="button" className={styles.button}>
              Upload
            </Button>
          </Upload>

          <Dropdown
            disabled={readOnly}
            menu={{
              items: documents.filter(
                (item) =>
                  !values?.attachments?.some((file) => file.key === item.key),
              ),
              onClick: onSelectFileFromSelect,
            }}
            trigger={['click']}
          >
            <div className={clsx('ui', 'primary', 'button', styles.button)}>
              <Icon name="paperclip" /> from WI
              <Icon name="angle down" />
            </div>
          </Dropdown>
          {values?.attachments?.length > 1 && (
            <Checkbox
              onChange={handleChange}
              name="toMergeDocuments"
              checked={values.toMergeDocuments}
            >
              Merge Documents
            </Checkbox>
          )}
        </div>
        <div>
          <Button
            type="submit"
            className={styles.button}
            disabled={!values.to.length || !isValid || readOnly}
          >
            Send
          </Button>
        </div>
      </div>
    </form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues({
    defaultValues: {
      from,
      to,
      cc,
      subject,
      body,
      attachments,
      toMergeDocuments,
      templateId,
    },
  }) {
    return {
      attachments: attachments || [],
      cc: cc || [],
      to: to || [],
      from: from || '',
      subjectLine: subject || '',
      body: body || '',
      toMergeDocuments: toMergeDocuments || false,
      templateId: templateId || '',
    };
  },
  validationSchema: () => {
    return object().shape({
      to: array().of(
        string().test('to-test', 'wrong email', (value) => {
          if (value.indexOf('<') > 0 && value.indexOf('>') > 0) {
            return Boolean(
              value
                .substring(value.indexOf('<') + 1, value.indexOf('>'))
                .toLowerCase()
                .match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                ),
            );
          }
          return Boolean(
            value
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              ),
          );
        }),
      ),
      cc: array().of(
        string().test('to test', 'wrong email', (value) => {
          if (value.indexOf('<') > 0 && value.indexOf('>') > 0) {
            return Boolean(
              value
                .substring(value.indexOf('<') + 1, value.indexOf('>'))
                .toLowerCase()
                .match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                ),
            );
          }
          return Boolean(
            value
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              ),
          );
        }),
      ),
    });
  },
  handleSubmit: async (
    values,
    { props: { handleSubmit }, resetForm, setErrors },
  ) => {
    await handleSubmit(values)
      .then(() => {
        resetForm();
      })
      .catch((e) => {
        setErrors(e.errors);
      });
  },
})(NewEmailForm);
