import {
  LOAD_GROUPING_WORK_ITEM_DOCUMENTS_LOADING,
  LOAD_GROUPING_WORK_ITEM_DOCUMENTS_SUCCESS,
  ON_DOCUMENT_DROP,
  ON_PAGE_DROP,
  SET_GROUPING_DATA,
  ADD_UNASSIGNED_DOCUMENT_SUCCESS,
  REMOVE_WORK_ITEM_SUCCESS,
  ADD_NEW_WORK_ITEM_SUCCESS,
  RESET_GROUPING,
  CHANGE_DOCUMENT_TYPE,
  REMOVE_DOCUMENT,
  GET_GROUPING_LEFT_SIDE_LOADING,
  GET_GROUPING_LEFT_SIDE_SUCCESS,
  GET_GROUPING_RIGHT_SIDE_SUCCESS,
  GET_GROUPING_RIGHT_SIDE_LOADING,
  CLONE_WORK_ITEM_SUCCESS,
} from '@constants/action-types';
import {
  appendDocumentToWorkItem,
  appendPagesToDocument,
  getWorkItemsLeftSide,
  getWorkItemsRightSide,
  removeDocumentById,
  removePagesById,
} from '@components/GroupingNew/helpers';

const defaultState = {
  leftSide: {
    isLoading: true,
    data: {},
  },
  rightSide: {
    isLoading: true,
    data: [],
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_GROUPING_RIGHT_SIDE_LOADING: {
      return { ...state, rightSide: { isLoading: true, data: [] } };
    }
    case GET_GROUPING_RIGHT_SIDE_SUCCESS: {
      return {
        ...state,
        rightSide: { isLoading: false, data: action.payload },
      };
    }
    case LOAD_GROUPING_WORK_ITEM_DOCUMENTS_LOADING: {
      return {
        ...state,
        rightSide: {
          ...state.rightSide,
          data: state.rightSide.data.map((workItem) => {
            if (workItem.id === action.payload) {
              return { ...workItem, isLoading: true };
            }
            return workItem;
          }),
        },
      };
    }
    case LOAD_GROUPING_WORK_ITEM_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        rightSide: {
          ...state.rightSide,
          data: state.rightSide.data.map((workItem) => {
            if (workItem.id === action.payload.id) {
              return { ...action.payload, isLoading: false };
            }
            return workItem;
          }),
        },
      };
    }
    case ON_DOCUMENT_DROP: {
      const { from, to, oldWorkItemId, documentId, newWorkItemId } =
        action.payload;
      const workItemsFrom = state[from]?.data;
      const workItemsTo = state[to]?.data;

      const draggedDocument = workItemsFrom
        .find((workItem) => workItem.id === oldWorkItemId)
        .documents.find((document) => document.id === documentId);

      const withRemovedDocument = removeDocumentById(
        workItemsFrom,
        oldWorkItemId,
        documentId,
      );
      const withAddedDocument = appendDocumentToWorkItem(
        from === to ? withRemovedDocument : workItemsTo,
        draggedDocument,
        newWorkItemId,
      );
      return {
        rightSide: {
          ...state.rightSide,
          data: getWorkItemsRightSide(
            to,
            from,
            withAddedDocument,
            withRemovedDocument,
            state.rightSide.data,
          ),
        },
        leftSide: {
          ...state.leftSide,
          data: getWorkItemsLeftSide(
            to,
            from,
            withAddedDocument,
            withRemovedDocument,
            state.leftSide.data,
          ),
        },
      };
    }
    case ON_PAGE_DROP: {
      const {
        from,
        newDocumentId,
        newWorkItemId,
        oldDocumentId,
        oldWorkItemId,
        pageIds,
        to,
      } = action.payload;
      const workItemsFrom = state[from].data;
      const workItemsTo = state[to].data;
      const draggedPages = pageIds.map((selectedPage) => {
        return workItemsFrom
          .find((workItem) => workItem.id === oldWorkItemId)
          .documents.find((document) => document.id === oldDocumentId)
          .pages.find((page) => selectedPage === page.id);
      });
      // get array of pages in document where user drop page
      const pagesTo = workItemsTo
        .find((workItem) => workItem.id === newWorkItemId)
        .documents.find((document) => document.id === newDocumentId);

      // index for correct order page if user select order for page
      const replacePageIndex = pagesTo.pages.findIndex(
        (page) => page.id === action.payload.replaceThisPageTo,
      );
      const workItemsWithRemovedPage = removePagesById(
        workItemsFrom,
        oldDocumentId,
        oldWorkItemId,
        pageIds,
      );

      const workItemsWithAddedPage = appendPagesToDocument(
        from === to ? workItemsWithRemovedPage : workItemsTo,
        newDocumentId,
        newWorkItemId,
        draggedPages,
        replacePageIndex,
      );

      return {
        rightSide: {
          ...state.rightSide,
          data: getWorkItemsRightSide(
            to,
            from,
            workItemsWithAddedPage,
            workItemsWithRemovedPage,
            state.rightSide.data,
          ),
        },
        leftSide: {
          ...state.leftSide,
          data: getWorkItemsLeftSide(
            to,
            from,
            workItemsWithAddedPage,
            workItemsWithRemovedPage,
            state.leftSide.data,
          ),
        },
      };
    }
    case SET_GROUPING_DATA: {
      return {
        ...action.payload,
      };
    }
    case REMOVE_WORK_ITEM_SUCCESS: {
      const { from, id } = action.payload;
      const workItemsFrom = state[from].data;
      const newWorkItemsArray = workItemsFrom.filter((workItem) => {
        return workItem.id !== id;
      });
      return {
        ...state,
        leftSide: {
          ...state.leftSide,
          data: from === 'leftSide' ? newWorkItemsArray : state.leftSide.data,
        },
        rightSide: {
          ...state.rightSide,
          data: from === 'rightSide' ? newWorkItemsArray : state.rightSide.data,
        },
      };
    }
    case ADD_NEW_WORK_ITEM_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        rightSide: {
          ...state.rightSide,
          data: [payload, ...state.rightSide.data],
        },
      };
    }
    case RESET_GROUPING: {
      return defaultState;
    }
    case ADD_UNASSIGNED_DOCUMENT_SUCCESS:
    case REMOVE_DOCUMENT:
    case CHANGE_DOCUMENT_TYPE: {
      const { from, wiId, newWI } = action.payload;
      const workItemsFrom = state[from].data;

      const newWorkItemsArray = workItemsFrom.map((workItem) => {
        return workItem.id === wiId ? newWI : workItem;
      });
      return {
        ...state,
        rightSide: {
          ...state.rightSide,
          data: from === 'rightSide' ? newWorkItemsArray : state.rightSide.data,
        },
        leftSide: {
          data: from === 'leftSide' ? newWorkItemsArray : state.leftSide.data,
        },
      };
    }
    case GET_GROUPING_LEFT_SIDE_LOADING: {
      return {
        ...state,
        leftSide: {
          isLoading: true,
          data: [],
        },
      };
    }
    case GET_GROUPING_LEFT_SIDE_SUCCESS: {
      return {
        ...state,
        leftSide: {
          isLoading: false,
          data: [action.payload],
        },
      };
    }
    case CLONE_WORK_ITEM_SUCCESS: {
      return {
        ...state,
        rightSide: {
          ...state.rightSide,
          data: [action.payload, ...state.rightSide.data],
        },
      };
    }
    default: {
      return state;
    }
  }
};
